import type { ReactNode } from "react";

import { useBreakpoints } from "@shared/hooks";

import * as styles from "./AuthHeaderWrapper.styles";

interface Props {
  children: ReactNode;
  includeBorder?: boolean;
  includeTopPadding?: boolean;
}

const AuthHeaderWrapper = ({ children, includeTopPadding = true, includeBorder }: Props) => {
  const { tablet } = useBreakpoints();

  return (
    <div
      css={[
        styles.wrapper,
        includeTopPadding && tablet.greaterThan && styles.wrapperPadding,
        includeBorder && tablet.greaterThan && styles.bottomBorder,
      ]}
    >
      {children}
    </div>
  );
};

export { AuthHeaderWrapper };
