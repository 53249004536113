import { useTranslation } from "react-i18next";

import { Spacer, Stepper, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./PractitionerOnboardingStepper.styles";

interface Props {
  currentStep: number;
}

const PractitionerOnboardingStepper = ({ currentStep }: Props) => {
  const { phoneLarge } = useBreakpoints();
  const { t } = useTranslation();
  const { isCanada } = useSharedGlobalConfig();

  const NUMBER_OF_STEPS = isCanada ? 4 : 5;
  const text = `${t(l.sharedCommon.Step)} ${currentStep}/${NUMBER_OF_STEPS}`;

  return (
    <div css={styles.wrapper}>
      <Typography type="h5" isMarginless>
        <span css={styles.stepText}>{text}</span>
      </Typography>
      <Spacer height="quarter" />
      {phoneLarge.greaterThan && (
        <Stepper
          size="small"
          steps={NUMBER_OF_STEPS}
          currentStep={currentStep}
          showStatusLabel
          hideAtoms
          isRounded
          isGray
        />
      )}
      <Spacer height={phoneLarge.greaterThan ? "oneHalf" : "one"} />
    </div>
  );
};

export { PractitionerOnboardingStepper };
